.Gryffindor {
  background-color: red;
  font-family: "Allerta Stencil", sans-serif;
  margin: 1rem;

  width: 226px;
}

.text1 {
  font-size: small;
}

.text2 {
  font-size: small;
}

.Hufflepuff {
  background-color: yellow;
  color: purple;
  font-family: "Allerta Stencil", sans-serif;
  margin: 1rem;
  font-size: smaller;
  width: 226px;
}

.Slytherin {
  background-color: green;
  font-family: "Allerta Stencil", sans-serif;
  margin: 1rem;
  font-size: smaller;
  width: 226px;
}

.Ravenclaw {
  background-color: blue;
  font-family: "Allerta Stencil", sans-serif;
  margin: 1rem;

  width: 226px;
}

.img {
  width: 200px;
  height: 200px;
}

.selected {
  font-family: "Allerta Stencil", sans-serif;
  font-size: 143%;
  color: red;
}

.houses {
  display: none;
}

@media only screen and (min-width: 375px) {
  .houses {
    display: flex;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .Gryffindor {
    background-color: red;
    color: white;
    font-family: "Allerta Stencil", sans-serif;
    width: 226px;

    margin: 1rem;
  }

  .Hufflepuff {
    background-color: yellow;
    color: gray;
    font-family: "Allerta Stencil", sans-serif;
    width: 226px;

    margin: 1rem;
  }

  .Slytherin {
    background-color: green;
    color: orange;
    font-family: "Allerta Stencil", sans-serif;
    width: 226px;

    margin: 1rem;
  }
  .Ravenclaw {
    background-color: blue;
    color: white;
    font-family: "Allerta Stencil", sans-serif;
    width: 226px;

    margin: 1rem;
  }

  main {
    display: flex;
  }

  .img {
    width: 200px;
    height: 200px;
  }
  .selected {
    font-family: "Allerta Stencil", sans-serif;
    color: red;
    display: flex;
  }

  .houses {
    display: flex;
    margin: 0 auto;
  }
}

/*font-family: 'Aldrich', sans-serif;
font-family: 'Allerta Stencil', sans-serif;
font-family: 'Oi', cursive;
font-family: 'Zen Tokyo Zoo', cursive;
font-family: 'Tourney', cursive;*/
